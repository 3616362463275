import React, { useEffect, useState } from "react"
import DropDown from "./drop-down"
import { Link } from "react-scroll"

const RedirectDropdown = ({ links, isNumbered, className, noWrapper }) => {
  const [currentNavValue, setCurrentNavValue] = useState("")
  const [array, setArray] = useState([])
  const [param, setParam] = useState("")

  useEffect(() => {
    setParam(window.location.pathname)
  }, [])

  let paramSlug = param.replace(/\//g, "")

  useEffect(() => {
    let arr = links[0].linkName.map((el, idx) => {
      let slug = links[0].slug[idx].replace(/\//g, "")
      if (slug === paramSlug) {
        setCurrentNavValue(el)
      }
      return (
        <>
          <a className="dropdown-item" href={links[0].slug[idx]}>
            {isNumbered && <div className="s-no">{idx + 1} </div>}
            <div className="link-name">
              <p> {el}</p>
            </div>{" "}
          </a>
        </>
      )
    })
    setArray(arr)
  }, [paramSlug])

  return <DropDown items={array} currentVal={currentNavValue} className={className} noWrapper={noWrapper}/>
}

const InPageDropDown = ({ links, className, noWrapper }) => {
  const [currentNavValue, setCurrentNavValue] = useState("")
  const [array, setArray] = useState([])

  useEffect(() => {
    let arr = links.map((fgl, fglIndex) => {
      setCurrentNavValue(links[0])
      return (
        <div className="dropdown-item">
          <div className="ch-title p14" role="button" tabIndex={0}>
            <Link
              offset={-100}
              to={fglIndex}
              onSetActive={() => setCurrentNavValue(fgl)}
              spy={true}
              activeClass="imactive"
            >
              <p>{fgl}</p>
            </Link>
          </div>
        </div>
      )
    })
    setArray(arr)
  }, [])

  return <DropDown items={array} currentVal={currentNavValue} className={className} noWrapper={noWrapper}/>
}

export { RedirectDropdown, InPageDropDown }
