import { graphql, Link, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import EsArrowLeft from "../assets/images/resources/es-arrow-left.png"
import EsArrowRight from "../assets/images/resources/es-arrow-right.png"
import EsBook from "../assets/images/resources/es-book.png"
import { RedirectDropdown } from "../components/common/dropdown-component/makeDropdown"
import Frame from "../components/common/frame"
import LatestBlogCards from "../components/common/latest-blog-cards"
import Navigation from "../components/navigation"
import SocialShare from "../components/social_share"
import CTAThankYou from "../components/utility/cta_thank_you"

function ResourceEbook({ pageContext }) {
  const { resource } = pageContext

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentResourceEbook {
            SuperOps {
              posts(first: 3, orderBy: date_DESC, where: { isBlog: Yes }) {
                title
                coverImage {
                  id
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 770 } }
                    }
                  )
                }
                tags
                slug
              }
              pages(where: { title: "Resources — Ebook" }) {
                title
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
              }
              links(where: { section: "E-Book" }) {
                linkName
                slug
              }
            }
            site {
              siteMetadata {
                url
                twitterHandle
              }
            }
          }
        `}
        render={data => (
          <div className="ebook-single-main">
            {data.SuperOps.pages.map(page => {
              const { title, navigationBlock } = page

              const twitterHandle = data.site.siteMetadata.twitterHandle
              const url = data.site.siteMetadata.url
              const shareTags = []

              return (
                <>
                  <Frame
                    seo={resource.seo[0]}
                  >
                    <header>
                      <Navigation links={navigationBlock} page={title} />
                    </header>

                    {resource.isFirstChapter === "Yes" ? (
                      <section className="es-banner-wrap">
                        <Container>
                          <center>
                            <div
                              className="outer"
                              style={{ background: resource.bgColor }}
                            >
                              <Row>
                                <Col md={5}>
                                  <div className="cover">
                                    <img
                                      src={resource.image.url}
                                      height="100"
                                      width="100"
                                      alt="cover"
                                    />
                                  </div>
                                </Col>
                                <Col md={12} lg={7}>
                                  <div className="category">
                                    <p>
                                      {" "}
                                      {resource.category} {resource.seo.title}{" "}
                                    </p>
                                    <div className="line" />
                                  </div>
                                  <div className="title">
                                    <h1> {resource.title}</h1>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className="inner">
                              <Row>
                                <Col md={5}>
                                  <div className="rs-hide-tab">
                                    <div className="blog-featured-author-single rs-auth-single mw280">
                                      {resource.author.team !== "Yes" ? (
                                        <Link
                                          to={`/blog/author/${resource.author.slug}/`}
                                          className="no-deco"
                                        >
                                          <div className="row no-gutters w228 w218">
                                            <div className="col-3">
                                              {/* <LazyLoad throttle={200} height={200}> */}
                                              <img
                                                src={
                                                  resource.author.picture.url
                                                }
                                                className="blog-featured-author-img objcvr"
                                                alt={resource.author.name}
                                                width="8"
                                                height="8"
                                              />
                                              {/* </LazyLoad> */}
                                            </div>
                                            <div className="col-9">
                                              <div className="blog-featured-author-name">
                                                {resource.author.name}
                                              </div>
                                              <div className="blog-featured-author-title">
                                                {resource.author.title}
                                              </div>
                                            </div>
                                          </div>
                                        </Link>
                                      ) : (
                                        <div className="row no-gutters w228 w218">
                                          <div className="col-3">
                                            {/* <LazyLoad throttle={200} height={200}> */}
                                            <img
                                              src={resource.author.picture.url}
                                              className="blog-featured-author-img objcvr"
                                              alt={resource.author.name}
                                              width="8"
                                              height="8"
                                            />
                                            {/* </LazyLoad> */}
                                          </div>
                                          <div className="col-9">
                                            <div className="blog-featured-author-name">
                                              {resource.author.name}
                                            </div>
                                            <div className="blog-featured-author-title">
                                              {resource.author.title}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>

                                    <p className="blog-illustration mt32">
                                      {!!resource.credits &&
                                        parse(resource.credits.html)}
                                    </p>
                                  </div>
                                </Col>
                                <Col md={7}>
                                  <div className="excerpt">
                                    <p>{parse(resource.excerpt.html)}</p>
                                  </div>

                                  <div className="rs-show-tab">
                                    <div className="blog-featured-author-single rs-auth-single mw280">
                                      {resource.author.team !== "Yes" ? (
                                        <Link
                                          to={`/blog/author/${resource.author.slug}/`}
                                          className="no-deco"
                                        >
                                          <div className="row no-gutters w228 w218">
                                            <div className="col-3">
                                              {/* <LazyLoad throttle={200} height={200}> */}
                                              <img
                                                src={
                                                  resource.author.picture.url
                                                }
                                                className="blog-featured-author-img objcvr"
                                                alt={resource.author.name}
                                                width="8"
                                                height="8"
                                              />
                                              {/* </LazyLoad> */}
                                            </div>
                                            <div className="col-9">
                                              <div className="blog-featured-author-name">
                                                {resource.author.name}
                                              </div>
                                              <div className="blog-featured-author-title">
                                                {resource.author.title}
                                              </div>
                                            </div>
                                          </div>
                                        </Link>
                                      ) : (
                                        <div className="row no-gutters w228 w218">
                                          <div className="col-3">
                                            {/* <LazyLoad throttle={200} height={200}> */}
                                            <img
                                              src={resource.author.picture.url}
                                              className="blog-featured-author-img objcvr"
                                              alt={resource.author.name}
                                              width="8"
                                              height="8"
                                            />
                                            {/* </LazyLoad> */}
                                          </div>
                                          <div className="col-9">
                                            <div className="blog-featured-author-name">
                                              {resource.author.name}
                                            </div>
                                            <div className="blog-featured-author-title">
                                              {resource.author.title}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>

                                    <p className="blog-illustration mt32">
                                      {!!resource.credits &&
                                        parse(resource.credits.html)}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </center>
                        </Container>

                        <div className="inner-mob">
                          <Row>
                            <Col md={7}>
                              <div className="excerpt">
                                <p>{parse(resource.excerpt.html)}</p>
                              </div>

                              <div className="rs-show-tab">
                                <div className="blog-featured-author-single rs-auth-single mw280">
                                  {resource.author.team !== "Yes" ? (
                                    <Link
                                      to={`/blog/author/${resource.author.slug}/`}
                                      className="no-deco"
                                    >
                                      <div className="row no-gutters w228 w218">
                                        <div className="col-3">
                                          {/* <LazyLoad throttle={200} height={200}> */}
                                          <img
                                            src={resource.author.picture.url}
                                            className="blog-featured-author-img objcvr"
                                            alt={resource.author.name}
                                            width="8"
                                            height="8"
                                          />
                                          {/* </LazyLoad> */}
                                        </div>
                                        <div className="col-9">
                                          <div className="blog-featured-author-name">
                                            {resource.author.name}
                                          </div>
                                          <div className="blog-featured-author-title">
                                            {resource.author.title}
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  ) : (
                                    <div className="row no-gutters w228 w218">
                                      <div className="col-3">
                                        {/* <LazyLoad throttle={200} height={200}> */}
                                        <img
                                          src={resource.author.picture.url}
                                          className="blog-featured-author-img objcvr"
                                          alt={resource.author.name}
                                          width="8"
                                          height="8"
                                        />
                                        {/* </LazyLoad> */}
                                      </div>
                                      <div className="col-9">
                                        <div className="blog-featured-author-name">
                                          {resource.author.name}
                                        </div>
                                        <div className="blog-featured-author-title">
                                          {resource.author.title}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <p className="blog-illustration mt32">
                                  {!!resource.credits &&
                                    parse(resource.credits.html)}
                                </p>
                              </div>
                            </Col>
                            <Col md={5}>
                              <div className="rs-hide-tab">
                                <div className="blog-featured-author-single rs-auth-single mw280">
                                  {resource.author.team !== "Yes" ? (
                                    <Link
                                      to={`/blog/author/${resource.author.slug}/`}
                                      className="no-deco"
                                    >
                                      <div className="row no-gutters w228 w218">
                                        <div className="col-3">
                                          {/* <LazyLoad throttle={200} height={200}> */}
                                          <img
                                            src={resource.author.picture.url}
                                            className="blog-featured-author-img objcvr"
                                            alt={resource.author.name}
                                            width="8"
                                            height="8"
                                          />
                                          {/* </LazyLoad> */}
                                        </div>
                                        <div className="col-9">
                                          <div className="blog-featured-author-name">
                                            {resource.author.name}
                                          </div>
                                          <div className="blog-featured-author-title">
                                            {resource.author.title}
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  ) : (
                                    <div className="row no-gutters w228 w218">
                                      <div className="col-3">
                                        {/* <LazyLoad throttle={200} height={200}> */}
                                        <img
                                          src={resource.author.picture.url}
                                          className="blog-featured-author-img objcvr"
                                          alt={resource.author.name}
                                          width="8"
                                          height="8"
                                        />
                                        {/* </LazyLoad> */}
                                      </div>
                                      <div className="col-9">
                                        <div className="blog-featured-author-name">
                                          {resource.author.name}
                                        </div>
                                        <div className="blog-featured-author-title">
                                          {resource.author.title}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <p className="blog-illustration mt32">
                                  {!!resource.credits &&
                                    parse(resource.credits.html)}
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </section>
                    ) : (
                      <div className="mt50" />
                    )}

                    <section
                      className="es-nav-wrap es-nav-wrap-top"
                      id="navContent"
                    >
                      <Container>
                        <center>
                          <div className="outer">
                            <div className="icon">
                              <img src={EsBook} alt="img" />
                            </div>

                            <div className="nav">
                              <p>
                                {" "}
                                {resource.chapterNumber}/
                                {resource.totalChapters}{" "}
                              </p>
                            </div>

                            <RedirectDropdown
                              links={data.SuperOps.links}
                              className="dropdown-wraper"
                              isNumbered
                              noWrapper
                            />
                            <div className="links dspnone">
                              <Link to={`/resources/books/${resource.slug}`}>
                                {" "}
                                Go to books{" "}
                                <img
                                  src={EsArrowRight}
                                  height="1"
                                  width="1"
                                  alt="img"
                                />{" "}
                              </Link>
                            </div>

                            <div className="share">
                              <SocialShare
                                socialConfig={{
                                  twitterHandle,
                                  config: {
                                    url: `${url}resources/books/${resource.slug}/chapter-${resource.chapterNumber}`,
                                    title: resource.title,
                                  },
                                }}
                                tags={shareTags}
                                direction="vrt"
                              />
                            </div>
                          </div>
                        </center>
                      </Container>
                    </section>

                    <section className="es-content-wrap">
                      <Container>
                        <center>
                          <div className="outer">
                            <div className="ch-no">
                              <p>CHAPTER {resource.chapterNumber}</p>
                              <div className="line" />
                            </div>
                            <div className="ch-title">
                              <h1> {resource.chapterTitle} </h1>
                            </div>
                          </div>
                          <div className="inner">
                            <div className="blogn-content blog-single-main-content">
                              {parse(resource.chapterContent.html)}
                            </div>

                            <Row>
                              <Col xs={12}>
                                {resource.isFirstChapter === "Yes" ? null : (
                                  <div className="nav-prev">
                                    <Link
                                      to={`/resources/books/${resource.slug
                                        }/chapter-${parseInt(
                                          resource.chapterNumber,
                                          10
                                        ) - 1}`}
                                    >
                                      {" "}
                                      <img
                                        src={EsArrowLeft}
                                        height="1"
                                        width="1"
                                        alt="img"
                                      />{" "}
                                      previous{" "}
                                    </Link>
                                  </div>
                                )}

                                {resource.isLastChapter === "Yes" ? null : (
                                  <div className="nav-next">
                                    <Link
                                      to={`/resources/books/${resource.slug
                                        }/chapter-${parseInt(
                                          resource.chapterNumber,
                                          10
                                        ) + 1}`}
                                    >
                                      {" "}
                                      next{" "}
                                      <img
                                        src={EsArrowRight}
                                        height="1"
                                        width="1"
                                        alt="img"
                                      />{" "}
                                    </Link>
                                  </div>
                                )}
                              </Col>
                              <Col xs={6} className="ts-right dspnone">
                                <div className="links">
                                  <Link
                                    to={`/resources/books/${resource.slug}`}
                                  >
                                    {" "}
                                    go to books{" "}
                                    <img
                                      src={EsArrowRight}
                                      height="1"
                                      width="1"
                                      alt="img"
                                    />{" "}
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </center>
                      </Container>
                    </section>

                    <div className="blog-cards">
                      <LatestBlogCards
                        heading="Stay in the know!"
                        ctaText="explore library"
                        ctaLink="blog"
                        items={data.SuperOps.posts}
                      />
                    </div>

                    <CTAThankYou />
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default ResourceEbook