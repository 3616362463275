import { Link } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import "../../styles/component/latest-blog-cards.scss"

import LatestBlogItems from "../common/latest-blog-items"
import SVGIcon from "./SVGIcon"
function LatestBlogCards(props) {
  return (
    <div className="latest-blog-cards">
      <Container>
        <Row>
          <Col lg={3}>
            <div
            // data-sal="slide-up"
            // data-sal-delay="100"
            // data-sal-easing="ease-out-bounce"
            // data-sal-duration="700"
            >
              {/* <StaticImage
                className="position-relative"
                src={"../../assets/images/home/blog-decor.svg"}
              /> */}
              <SVGIcon name="home/blog-decor" className="position-relative" />
              <h2> {props.heading} </h2>
            </div>

            <span className="a14">
              <Link to={`/${props.ctaLink}`} className="position-relative link">
                {props.ctaText}
                <SVGIcon name="hypertext-arrow-right" className="arrow-right" />
              </Link>
            </span>
          </Col>
          <Col lg={9}>
            <LatestBlogItems items={props.items} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default LatestBlogCards
