import React from "react"
import { Dropdown } from "react-bootstrap"
import EsArrowDown from "../../../assets/images/resources/es-arrow-down.png"

let DropDown = props => {
  return (
    <div
      className={`${props.noWrapper ? "" : "drp-down-wrapper"} ${
        props.className
      }`}
    >
      <Dropdown className="sticky-top">
        <Dropdown.Toggle variant="success">
          <p className="current-value">{props.currentVal}</p>
          <img
            className="position-absolute"
            src={EsArrowDown}
            height="1"
            width="1"
            alt="img"
          />
        </Dropdown.Toggle>

        <Dropdown.Menu renderOnMount={true} id="drp-menu">
          {props.items}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default DropDown
